.notification-error.notification-error {
    background-color: red;
}

.notification__content {
    display: flex;
    align-items: center;
}

.notification__icon {
    margin-right: 10px;
}
