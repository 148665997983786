.Logo {
    background-color: white;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
}

.Logo img {
    height: 100%;
    width: 100px;
}

.login-logo {
    width: 80%;
    float: none;
}

.login-logo-text.login-logo-text {
    font-size: 6vw;
    direction: rtl;
}

@media (min-width: 600px) {
    .login-logo {
        width: 80%;
        float: left;
    }
}
