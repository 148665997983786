.photosUpload-spinner.photosUpload-spinner {
    position: fixed;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.compute-button.compute-button {
    width: 250px;
    margin: 10px;
}
