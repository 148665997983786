.buttonsPhotoEdit {
    width: 125px;
}

.buttonsPhotoProceed.buttonsPhotoProceed {
    width: 20%;
    min-width: 125px;
    margin: 10px;
}

.buttonsPhotoProceedBlock {
    margin: 0 60px;
    display: flex;
    justify-content: space-between;
}
.edit-photo-border {
    max-height: 600px;
    max-width: 600px;
    height: 100%;
    width: 45vw;
    min-width: 350px;
    border: 1px solid black;
    border-radius: 4px;
}

.edit-photo-border-canvas {
    border: 1px solid black;
    border-radius: 4px;
}

