.HeaderLink {
    box-sizing: border-box;
    display: block;
    width: 100vw;
}

.HeaderLink a {
    color: white;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.HeaderLink a:active,
.HeaderLink a.active {
    border-bottom: 4px solid #ad0000;
    background-color: #0091f6;
    width: 100%;
}

@media (min-width: 500px) {
    .HeaderLink {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
        background-color: #00a0ff;
    }

    .HeaderLink a {
        color: white;
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }

    .HeaderLink a:active,
    .HeaderLink a.active {
        background-color: #0091f6;
        border-bottom: 4px solid #ad0000;
        color: white;
    }
}

.header-button.header-button {
    width: 100%;
    padding: 0;
}
