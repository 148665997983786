.personalInfo-textfield.personalInfo-textfield {
    max-width: 285px;
    margin: 10px 25px 20px;
}

.personalInfo-title.personalInfo-title {
    margin: 10px 0;
    font-size: 25px;
}

.personalInfo-paper {
    padding: 10px;
    max-width: 500px;
    margin: auto;
}
