.photoCard.photoCard {
    padding: 0 15px 10px;
}

.photo-card-header.photo-card-header {
    padding: 5px;
}


@media (min-width: 800px) {
.photo-sm-grid.photo-sm-grid {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
}
}
