.profile-div-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    margin-bottom: 50px;
}

.patients-div-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}


.profile-h1-wrapper {
    width: 100%;
    text-align: center;
}

.profile-button-wrapper.profile-button-wrapper {
    margin: 20px 0;
    max-width: 200px;
}
