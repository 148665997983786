.results-buttons-block {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 150px;
}

.results-button.results-button {
    margin-bottom: 20px;
}
